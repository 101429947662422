import { gql } from '@apollo/client';

export const TRACK_OPPORTUNITY_VIEWS = gql`
  mutation trackViews($slug: String!, $ip: String ) {
    trackViews(slug: $slug, ip: $ip) {
      message
    }
  }
`;
export const PRE_SIGNED_URL = gql`
  mutation preSignedUrl($key: String) {
    preSignedUrl(key: $key) {
      url
    }
  }
`;
export const SAVE_WIDGET_SETTING = gql`
  mutation saveWidget($input: ImpactCenterViewSettingsInput!) {
    createImpactCenterViewSettings(input: $input) {
      name
    }
  }
`;
